<template>
<div>
  <card class="card-user">
    
    <p class="card-text"></p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <div>
        <image-upload
            ref="imageUpload"
            type="avatar"
            :src="imageUrl"
            select-text="modifier photo"
            @change="onAvatarChange">
        </image-upload>
      </div>
      <div class="justify-content-center">
        <input type="file" ref="file" style="display: none">
        <base-button class="text-uppercase" simple type="info" v-on:click="updateImage()" :disabled="image === null">{{ $t('buttons.BtnModify') }}</base-button>
      </div>
      <h5 class="title">{{this.firstName}} {{this.lastName}}</h5>
      <p class="description">{{this.function}}</p>
    </div>
    <div class="card-description">
    </div>

    <!-- <div slot="footer" class="button-container">
      <base-button class="btn-facebook" icon round>
        <i class="fab fa-facebook"></i>
      </base-button>
      <base-button class="btn-twitter" icon round>
        <i class="fab fa-twitter"></i>
      </base-button>
      <base-button class="btn-google" icon round>
        <i class="fab fa-google-plus"></i>
      </base-button>
    </div> -->
  </card>
</div>
</template>
<script>
import { ImageUpload, BaseAlert} from 'src/components';

export default {
   components: {
    ImageUpload,
    BaseAlert
  },
   created() {
    this.token = localStorage.getItem("token_jwt");
    
    this.utilisateur = JSON.parse(localStorage.getItem('utilisateur'))
    this.id = this.utilisateur.id
    this.imageUrl = this.utilisateur.image
    this.firstName = this.utilisateur.first_name
    this.lastName = this.utilisateur.last_name
    this.function = this.utilisateur.function
  },
  data(){
    return {
      utilisateur: {},
      id:'',
      token: '',
      imageUrl: '',
      firstName:'',
      lastName:'',
      function:'',
      image: null
    }
  },
   methods:{
     removeFile(){
       this.but
     },
     updateImage(){
      if(this.image === null)
        return
      let formData = new FormData()
      formData.append('id', this.id)
      formData.append('image', this.image)
      this.axios
        .post(
          "https://api-auth-plf.wee-do-it.net/api/v1/update_img",formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
              'authorization': `Bearer ${this.token}`
              }
        })
        .then(response => {
          this.$notify({type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: this.$t('alerts.ImageUpdate') });
          localStorage.setItem('utilisateur',JSON.stringify(response.data.response))
          this.utilisateur = JSON.parse(localStorage.getItem('utilisateur'))
          this.imageUrl =  this.utilisateur.image
          this.$refs.imageUpload.removeFile()
        })
        .catch(err => {
          if (err.response.status == "401") {
            this.$store.commit('signOut')
            location.reload()
          }
        });
     },
     onAvatarChange(file) {
      this.image = file;
     }
   }
};
</script>
<style></style>
