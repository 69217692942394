<template>
  <div v-html="Ctemplate" style="background-color: white;">
  </div>
</template>

<script>
  export default {
    data() {
      return {
        Ctemplate: ''
      }
    },
    created() {
      const url_api = 'https://apimyaffil.wee-do-it.net/api/apercu_campagne_template'
      let accessToken = localStorage.getItem("token_jwt");

      this.axios
        .post(
          url_api,
          {
            idf: this.$route.params.idf,
            id_visuel: this.$route.params.id_visuel,
            campagne: (this.$route.params.hostname != 'plf') ? this.$route.params.campagne : this.$route.params.campagne.replace(/ /g,"%20"),
            hostname: this.$route.params.hostname
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            }
          }).then(response => {

            this.Ctemplate = response.data

          }).catch(error => {
            console.log(error)
          })
    },
  }
</script>
