<template>
  <div>
    <h2 class="text-center mb-5">Bienvenue sur la nouvelle plateforme Weedo Perf </h2>
      <h5 class="text-center mb-5">Nous sommes ravie de vous accueillir sur notre nouvelle plateforme Weedo Perf. :) <br>
        Étant donné que c'est votre première connexion, nous vous recommandons pour des raisons de sécurité de renouveler votre mot de passe.  </h5>
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 mr-auto">
          <div class="card card-custom-white">
            <div class="card-body">
              <base-alert type="default" dismissible v-if="ereurs.length">
                <span> 
                  <template v-if="ereurs[0].includes('Requête erronée') || ereurs[0].includes('Wrong request')">
                    {{ ereurs[0] }}
                    <u><a href="mailto:webmaster@weedoit.fr">Support</a></u>
                  </template>
                  <template v-else> {{ ereurs[0] }} </template>
                </span>
              </base-alert>
              <div class="row">
                <div class="col-md-12">
								  <p style="color:#303030">Pour réinitialiser votre mot de passe, saisissez l'adresse e-mail que vous utilisez pour vous connecter à votre compte.</p>
								</div>
              </div>
              <hr style="color:#51347c; background: #51347c ">
              <form @submit.prevent='Check'>
                <div class="row my-4">
                  <div class="col-md-12 my-2">
                    <input id="Email" type="text" class="form-control-custom" placeholder="E-mail" v-model="email" required>
                  </div>
                </div>
                <base-button native-type="submit" class="btn btn-fill btn-info my-4 pull-right" type="default">Envoyer</base-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from 'src/cognito_conf.js'
var AMAZON_IDENTITY = require('amazon-cognito-identity-js')
import { BaseAlert } from 'src/components'

export default {
  components: {
    BaseAlert
  },
  data () {
    return {
      email: '',
      ereurs: []
    }
  },
  created () {
    document.title = 'Mot de passe - Weedo IT'
  },
  beforeCreate() {
    document.body.className = 'login';
  },
  methods: {
    Check () {
      this.ereurs = []
      var poolData = {
        UserPoolId: config.cognito.userPoolId,
        ClientId: config.cognito.clientId
      }
      var userPool = new AMAZON_IDENTITY.CognitoUserPool(poolData)
      var userName = this.email.toLowerCase().replace(/ /g, '')

      var userData = {
        Username: userName,
        Pool: userPool
      }
      var cognitoUser = new AMAZON_IDENTITY.CognitoUser(userData)
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          sessionStorage.setItem('UserName', userName)
          this.$router.push({ name: 'ResetPassword' })
        }.bind(this),
        onFailure: function (err) {
          this.ereurs.push(this.translatemsg(err.code))
        }.bind(this)
      })
    },
    translatemsg(msg) {
      switch (msg) {
        case "UserNotFoundException":
          return this.$t('errors.UserNotFoundException');
      }
      return this.$t('errors.ServerError');
    }
  }
}
</script>
