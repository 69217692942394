<template>
  <div>
    <h2 class="text-center mb-5">Validation</h2>
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 mr-auto">
          <div class="card card-custom-white">
            <div class="card-body">
              <base-alert type="default" dismissible v-if="ereurs.length">
                <span> 
                  <template v-if="ereurs[0].includes('Requête erronée') || ereurs[0].includes('Wrong request')">
                    {{ ereurs[0] }}
                    <u><a href="mailto:webmaster@weedoit.fr">Support</a></u>
                  </template>
                  <template v-else> {{ ereurs[0] }} </template>  
                </span>
              </base-alert>
              <div class="row">
                <div class="col-md-12">
								  <p style="color:#303030" v-if='!show'>
                    Veuillez vérifier votre boite e-mail, vous allez bientôt recevoir notre message.
                    Si vous n’avez pas reçu le mail d’ici quelques minutes, pensez à vérifier vos courriers indésirables, 
                    ou bien consultez notre support technique: webmaster@weedoit.fr
                  </p>
								</div>
              </div>
              <hr style="color:#51347c; background: #51347c ">
              <form @submit.prevent="validation" v-if='!show'>
                <div class="row my-4">
                  <div class="col-md-12 my-2">
                    <input id="validationcode" type="text" class="form-control-custom" placeholder="Saisir votre code de validation" v-model="validationcode" required>
                  </div>
                </div>
                <base-button native-type="submit" class="animation-on-hover my-4 pull-right" type="info">
                  Valider
                </base-button>
              </form>
              <div class="col-md-12" v-if='show'>
                <h3 style="color:#303030">{{ $t('errors.UserNotEnabled') }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from 'src/cognito_conf.js'
var AMAZON_IDENTITY = require('amazon-cognito-identity-js')

export default {
  data () {
    return {
      show: false,
      ereurs: [],
      validationcode: null
    }
  },
  created () {
    if (!sessionStorage.getItem('userregister')) {
      this.$router.push({ name : 'Login' })
    }
  },
  beforeCreate() {
    document.body.className = 'login';
  },
  methods: {
    validation () {
      this.ereurs = []
      var userCode = this.validationcode
      var poolData = {
        UserPoolId: config.cognito.userPoolId,
        ClientId: config.cognito.clientId
      }
      var userPool = new AMAZON_IDENTITY.CognitoUserPool(poolData)
      var userData = {
        Username: sessionStorage.getItem('userregister'),
        Pool: userPool
      }
      var cognitoUser = new AMAZON_IDENTITY.CognitoUser(userData)
      cognitoUser.confirmRegistration(userCode, true,async function (err, result) {
        if (err) {
          this.ereurs.push(this.translatemsg(err.code))
          return
        }
        this.show = true
        await this.sleep(4000);
        this.$router.push({ name : 'Login' })
      }.bind(this))
    },
    translatemsg(msg) {
      switch (msg) {
        case "CodeMismatchException":
          return this.$t('errors.CodeMismatchException');
      }
      return this.$t('errors.ServerError');
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
}
</script>
