<template>
  <div>
    <h2 class="text-center mb-5">{{ $t('labels.SignUp') }}</h2>
    <div class="container">
      <div class="row">
        <div class="col-md-12 mr-auto ml-auto">
          <simple-wizard data-color="green" :btn_finish="$t('buttons.BtnFinish')">
            <template slot="header">
              <h5 class="description">{{ $t('labels.SignUpHint')}} </h5>
              <template v-if="ereurs.length">
                  <div v-for="(error, index) in ereurs" :key="index">
                    <base-alert type="default" dismissible >
                      <li>{{error}}</li>
                    </base-alert>
                  </div>
              </template>
            </template>

            <wizard-tab :before-change="() => validateStep('step1')">
              <template slot="label">
                <i class="tim-icons icon-settings-gear-63"></i>
                <p>{{ $t("labels.Account") }}</p>
              </template>
              <first-step ref="step1" @on-validated="onStepValidated" @on-UserExist="UserExist"></first-step>
            </wizard-tab>

            <wizard-tab :before-change="() => validateStep('step2')">
              <template slot="label">
                <i class="tim-icons icon-single-02"></i>
                <p>{{ $t("labels.Profile") }}</p>
              </template>
              <second-step ref="step2" @on-validated="onStepValidated"></second-step>
            </wizard-tab>

            <wizard-tab :before-change="() => validateStep('step3')">
              <template slot="label">
                <i class="tim-icons icon-notes"></i>
                <p>{{ $t("labels.Preferences") }}</p>
              </template>
              <third-step ref="step3" @on-validated="onStepValidated"></third-step>
            </wizard-tab>
          </simple-wizard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from "./Register/FirstStep.vue";
import SecondStep from "./Register/SecondStep.vue";
import ThirdStep from "./Register/ThirdStep.vue";
import { SimpleWizard, WizardTab, BaseAlert } from "src/components";
import { config } from "src/cognito_conf.js";
var AMAZON_IDENTITY = require("amazon-cognito-identity-js");
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      wizardModel: {},
      ereurs: []
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab,
    BaseAlert
  },
  created() {
    document.title = "Registration - Weedo IT";
    if (this.getemail != '') {
      this.ereurs.push('Merci de compléter votre profil')
    }
  },
  beforeCreate() {
    document.body.className = 'login';
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
      if(model.step == "final"){
        this.wizardComplete()
      }
    },
    UserExist(message){
      this.ereurs.push(message)
    },
    wizardComplete() {
      this.$store.commit('setendregistration', true)
      this.ereurs = [];
      let formData = new FormData()
      let law = ""
      if(this.wizardModel.affilie)
        law += "af,"
      if(this.wizardModel.annonceur || this.wizardModel.agence_media)
        law += "an,"

      law = law.substring(0, law.length - 1)
      formData.append('first_name', this.wizardModel.firstname)
      formData.append('last_name', this.wizardModel.lastname)
      formData.append('email', this.wizardModel.email.toLowerCase())
      formData.append('society', this.wizardModel.society)
      formData.append('activity_area', this.wizardModel.activity)
      formData.append('address', this.wizardModel.address)
      formData.append('city', this.wizardModel.city)
      formData.append('country', this.wizardModel.country)
      formData.append('function', this.wizardModel.function)
      formData.append('comment', this.wizardModel.comment)
      formData.append('law', law)
      formData.append('phone', this.wizardModel.mobile)
      formData.append('postal_code', this.wizardModel.zipcode)
      formData.append('code_pays', this.wizardModel.cMobile)
      formData.append('indicatif', this.wizardModel.indicatif)
      formData.append('image', this.wizardModel.images)
      formData.append('language', this.wizardModel.langue)
      formData.append('orias', this.wizardModel.orias)
      formData.append('skype', this.wizardModel.skype)
      formData.append('tva_intracommunautaire', this.wizardModel.tva)
      formData.append('rcs', this.wizardModel.rcs)
      formData.append('type_of_broadcast', this.wizardModel.type_of_broadcast)
      formData.append('url_site', this.wizardModel.url_site)
      formData.append('thematic', this.wizardModel.thematic)
      formData.append('volume', this.wizardModel.volume)
      formData.append('newsletter', (this.wizardModel.newsletter==true)?1:0)
      formData.append('dedicated_email', (this.wizardModel.emails_dedies==true)?1:0)
      formData.append('target_email', (this.wizardModel.emailings_cibles==true)?1:0)
      formData.append('geolocated_email', (this.wizardModel.emailings_geolocalises==true)?1:0)
      formData.append('incentive', (this.wizardModel.incentive==true)?1:0)
      formData.append('click_validation', (this.wizardModel.clic_validation==true)?1:0)
      formData.append('co_registration', (this.wizardModel.co_registration==true)?1:0)
      formData.append('white_mark', (this.wizardModel.marque_blanche==true)?1:0)
      formData.append('site_under', (this.wizardModel.site_under==true)?1:0)
      formData.append('autres_procedes_diffusion', (this.wizardModel.autres==true)?1:0)
      var username = this.wizardModel.email.toLowerCase();
      var password = this.wizardModel.password;
      var poolData = {
        UserPoolId: config.cognito.userPoolId,
        ClientId: config.cognito.clientId
      };
      var userPool = new AMAZON_IDENTITY.CognitoUserPool(poolData);
      var attributeList = [];
      var dataEmail = {
        Name: "email",
        Value: this.wizardModel.email.toLowerCase()
      };
      var dataPersonalName = {
        Name: "family_name",
        Value: this.wizardModel.lastname
      };
      var dataPhoneNumber = {
        Name: "phone_number",
        Value: this.wizardModel.rMobile
      };
      var attributeEmail = new AMAZON_IDENTITY.CognitoUserAttribute(dataEmail);
      var attributePersonalName = new AMAZON_IDENTITY.CognitoUserAttribute(
        dataPersonalName
      );
      var attributePhoneNumber = new AMAZON_IDENTITY.CognitoUserAttribute(
        dataPhoneNumber
      );
      var userName = this.wizardModel.email.toLowerCase();
      attributeList.push(attributeEmail);
      attributeList.push(attributePersonalName);
      attributeList.push(attributePhoneNumber);
      userPool.signUp(username, password, attributeList, null, async function(err,result) {
        if (err) {
          if(err.message.includes("Password did not conform")){
            this.ereurs.push(this.translatemsg("PasswordNotConfirm"));
          } else {
            this.ereurs.push(this.translatemsg(err.message));
          }
          this.$store.commit('setendregistration', false)
          return;
        }
        await this.axios
          .post(
            "https://api-auth-plf.wee-do-it.net/api/v1/inscription",formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
                }
            }
          )
          .then(response => {
            console.log(response.data);
          })
          .catch(err => {
            console.log(err)
          });
        this.$store.commit('setendregistration', false)
        sessionStorage.setItem("userregister", userName);
        this.$router.push({ name : "ValidateEmail" });
      }.bind(this));
      return false;
    },
    translatemsg(msg) {
      switch (msg) {
        case "Invalid phone number format.":
          return this.$t("errors.InvalidePhoneNumber");
        case "User already exists":
          return this.$t("errors.UserExistsException");
        case "An account with the given email already exists.":
          return this.$t("errors.EmailExistsException");
        case "PasswordNotConfirm":
          return this.$t("errors.InvalidPasswordException");
      }
      return "Erreur inconnus";
    }
  },
  computed: {
    ...mapGetters([
      'getemail'
    ])
  }
};
</script>
