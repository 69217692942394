<template>
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <h2 class="info-text">{{ $t('labels.YourPreferences').toUpperCase() }}</h2>
    </div>
    <div class="col-sm-10">
      <h4>EMAILLING</h4>
      <div class="row mb-3">
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Emailling.NewsLetter').toUpperCase() }}</p>
          <base-switch
            v-model="model.newsletter"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Emailling.DedicatedEmail').toUpperCase() }}</p>
          <base-switch
            v-model="model.emails_dedies"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Emailling.TargetEmail').toUpperCase() }}</p>
          <base-switch
            v-model="model.emailings_cibles"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Emailling.GeolocatedEmail').toUpperCase() }}</p>
          <base-switch
            v-model="model.emailings_geolocalises"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
      </div>
      <h4>DIVERS</h4>
      <div class="row mb-3">
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.Incentive').toUpperCase() }}</p>
          <base-switch
            v-model="model.incentive"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.ClickValidation').toUpperCase() }}</p>
          <base-switch
            v-model="model.clic_validation"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.CoRegistration').toUpperCase() }}</p>
          <base-switch
            v-model="model.co_registration"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.WhiteMark').toUpperCase() }}</p>
          <base-switch
            v-model="model.marque_blanche"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.site_under').toUpperCase() }}</p>
          <base-switch
            v-model="model.site_under"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.autres').toUpperCase() }}</p>
          <base-switch
            v-model="model.autres"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui';
import { BaseSwitch } from 'src/components/index';

export default {
  components: {
    BaseSwitch,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      model: {
        newsletter: false,
        emails_dedies: false,
        emailings_cibles: false,
        emailings_geolocalises: false,
        incentive: false,
        clic_validation: false,
        co_registration: false,
        marque_blanche: false,
        site_under: false,
        autres: false,
        step: "final"
      },
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    }
  }
};
</script>
<style></style>
