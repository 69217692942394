<template>
  <card>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <h5 class="info-text">{{ $t("labels.Profile").toUpperCase() }}</h5>
      </div>

      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Society') + ' *'"
          name="society"
          v-model="model.society"
          :error="getError('society')"
          v-validate="'required|max:60'"
          :data-vv-as="$t('inputs.Society')"
        >
        </base-input>
      </div>

      <div class="col-sm-5">
        <base-input  :label="$t('inputs.Activity')  + ' *'" :error="getError('activity')">
          <el-select
            v-model="model.activity"
            placeholder="CHOISIR"
            class="select-primary"
            name="activity"
            v-validate="'required|max:60|min:3'"
            :data-vv-as="$t('inputs.Activity')"
            >
            <el-option
              v-for="a in activity"
              class="select-primary"
              :label="a.toUpperCase()"
              :value="a"
              :key="a"
            ></el-option>
          </el-select>
        </base-input>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-10">
        <center>
          <base-checkbox inline class="mb-3" v-model="model.affilie" :disabled="utilisateur.multi_droit || utilisateur.law == 'af' || utilisateur.law == 'ad' || utilisateur.law == 'tem'|| utilisateur.law == 'ma'">
            {{ $t('inputs.Law.Affiliates').toUpperCase() }}
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="model.annonceur" :disabled="utilisateur.multi_droit || utilisateur.law == 'an' || utilisateur.law == 'ad' || utilisateur.law == 'tem'|| utilisateur.law == 'ma'">
            {{ $t('inputs.Law.Advertiser').toUpperCase() }}
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="model.agence_media" :disabled="utilisateur.multi_droit || utilisateur.law == 'an' || utilisateur.law == 'ad' || utilisateur.law == 'tem'|| utilisateur.law == 'ma'">AGENCE MÉDIA</base-checkbox>
        </center>
      </div>
    </div>
    &nbsp;
    <div class="row justify-content-center">
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Lastname')"
          name="lastname"
          :placeholder="$t('inputs.Lastname')"
          v-model="model.lastname"
          :error="getError('lastname')"
          v-validate="'required|min:3'"
          addon-left-icon="tim-icons icon-single-02"
          :data-vv-as="$t('inputs.Lastname')"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Firstname')"
          name="firstname"
         :placeholder="$t('inputs.Firstname')"
          v-model="model.firstname"
          :error="getError('firstname')"
          v-validate="'required|min:3'"
          addon-left-icon="tim-icons icon-caps-small"
          :data-vv-as="$t('inputs.Firstname')"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Function')"
          name="function"
          v-model="model.function"
          :error="getError('function')"
          v-validate="'required|min:3'"
          :data-vv-as="$t('inputs.Function')"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <label>{{ $t('inputs.Address').toUpperCase() }}</label>
        <vue-google-autocomplete
            name="address"
            id="from_address"
            class="form-control"
            :placeholder="$t('inputs.Address')"
            v-model="model.address"
            v-on:placechanged="getFromAddress"
            :error="showErrorAddress"
            v-bind:style="showErrorAddress ? 'border-color:#ec250d' : ''"
            @change="checkAddress()"
        >
        </vue-google-autocomplete>
        <label class="error" style="color:#ec250d" v-show="showErrorAddress"> {{ $t('errors.AddressRequired') }}  </label>
      </div>
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.zipcode')"
          name="zipcode"
          required
          v-model="model.zipcode"
          :error="getError('zipcode')"
          v-validate="'required|min:5'"
          :data-vv-as="$t('inputs.zipcode')"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.City')"
          name="city"
          required
          v-model="model.city"
          :error="getError('city')"
          v-validate="'required|min:3'"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input :label="$t('inputs.Country')" :error="getError('country')">
          <el-select
            filterable
            v-model="model.country"
            class="select-primary"
            name="country"
            v-validate="modelValidations.country"
            :placeholder="$t('inputs.SelectCountry')"
            :data-vv-as="$t('inputs.Country')"
          >
            <el-option
              v-for="country in countryOptions"
              class="select-primary"
              :label="country.toUpperCase()"
              :value="country"
              :key="country"
            ></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.TvaIntra')"
          name="tva"
          v-model="model.tva"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <label>{{ $t('inputs.PhoneNumber').toUpperCase() }}</label>
        <vue-phone-number-input
          name="mobile"
          valid-color="#54347C"
          :no-example="true"
          :dark="true"
          :border-radius="8"
          :default-country-code='model.countryCode'
          dark-color="#000"
          error-color="#ec250d"
          :only-countries="this.countrysCode"
          :translations="{
            countrySelectorLabel: $t('inputs.CountryCode'),
            phoneNumberLabel: $t('inputs.PhoneNumber'),
          }"
          color="#988fc3"
          :error="showErrorMobile"
          v-validate="'required'"
          v-model="model.mobile"
          @input="checkMobile()"
          @update="onUpdate"
        >
        </vue-phone-number-input>
        <label class="error" style="color:#ec250d" v-show="showErrorMobile"> {{ $t('errors.MobileRequired') }} </label>
      </div>
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Email')"
          name="email"
          placeholder="email"
          v-model="model.email"
          :disabled="true"
          addon-left-icon="tim-icons icon-email-85"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input
          label="Skype"
          name="Skype"
          v-model="model.skype"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input :label="$t('inputs.Language')" :error="getError('Langue')">
          <el-select
            v-model="model.langue"
            class="select-primary"
            name="Langue"
            v-validate="'required'"
            :placeholder="$t('inputs.SelectLang')"
            :data-vv-as="$t('inputs.Language')"
          >
            <el-option
              v-for="l in languageOptions"
              class="select-primary"
              :label="l.toUpperCase()"
              :value="l"
              :key="l"
            ></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input
          label="RCS"
          name="RCS"
          v-model="model.rcs"
        >
        </base-input>
      </div>
       <div class="col-sm-5"></div>
      <div class="col-sm-5"  v-if="model.law == 'an' && model.activity == 'Assurances'">
        <base-input
          label="Orias"
          name="orias"
          required
          v-model="model.orias"
          :error="getError('orias')"
          v-validate="'required'"
        >
        </base-input>
      </div>
    </div>
    <!-- row 3 -->
    <div class="row justify-content-center" v-if="model.law == 'af'">
      <div class="col-sm-5">
        <base-input :label="$t('inputs.Themes')" :error="getError('thematic')">
          <el-select
            v-model="model.thematic"
            class="select-primary"
            name="Thématiques"
            :placeholder="$t('inputs.Themes')"
            :data-vv-as="$t('inputs.Themes')"
          >
            <el-option
              v-for="t in thematics"
              class="select-primary"
              :label="t"
              :value="t"
              :key="t"
            ></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.UrlSite')"
          name="URL"
          v-model="model.url_site"
          :error="getError('URL')"
          v-validate="'url'"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input :label="$t('inputs.DiffusionType')" :error="getError('diffusion')">
          <el-select
            v-model="model.type_of_broadcast"
            class="select-primary"
            name="Type_de_diffusion"
            :placeholder="$t('inputs.DiffusionType')"
            :data-vv-as="$t('inputs.DiffusionType')"
          >
            <el-option
              v-for="d in diffusiontype"
              class="select-primary"
              :label="d"
              :value="d"
              :key="d"
            ></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input
          label="Volume"
          name="volume"
          v-model="model.volume"
        >
        </base-input>
      </div>
    </div>
    <!-- row 7 -->
    &nbsp;
    <div class="row justify-content-center">
    <div class="col-sm-10" v-if="model.law == 'af'">
      <h4>EMAILLING</h4>
      <div class="row mb-3">
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Emailling.NewsLetter').toUpperCase() }}</p>
          <base-switch
            v-model="model.newsletter"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Emailling.DedicatedEmail').toUpperCase() }}</p>
          <base-switch
            v-model="model.emails_dedies"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Emailling.TargetEmail').toUpperCase() }}</p>
          <base-switch
            v-model="model.emailings_cibles"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Emailling.GeolocatedEmail').toUpperCase() }}</p>
          <base-switch
            v-model="model.emailings_geolocalises"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
      </div>
      <h4>DIVERS</h4>
      <div class="row mb-3">
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.Incentive').toUpperCase() }}</p>
          <base-switch
            v-model="model.incentive"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.ClickValidation').toUpperCase() }}</p>
          <base-switch
            v-model="model.clic_validation"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.CoRegistration').toUpperCase() }}</p>
          <base-switch
            v-model="model.co_registration"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.WhiteMark').toUpperCase() }}</p>
          <base-switch
            v-model="model.marque_blanche"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.site_under').toUpperCase() }}</p>
          <base-switch
            v-model="model.site_under"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
        <div class="col-md-6">
          <p class="category">{{ $t('inputs.Diverse.autres').toUpperCase() }}</p>
          <base-switch
            v-model="model.autres"
            type="primary"
            on-text="ON"
            off-text="OFF"
          ></base-switch>
        </div>
      </div>
    </div>
    </div>
    <!-- alerte reset password error -->
    <base-alert type="default" dismissible v-if="ereurs.length">
      <span>
        <template v-if="ereurs[0].includes('Requête erronée') || ereurs[0].includes('Wrong request')">
          {{ ereurs[0] }}
          <u><a href="mailto:webmaster@weedoit.fr">Support</a></u>
        </template>
        <template v-else> {{ ereurs[0] }} </template>
      </span>
    </base-alert>
    <!-- alerte reset password error -->
    <!-- row 8 -->
    <!-- <div class="row justify-content-center">
      <div class="col text-center">
        <base-button v-if="!loading" simple type="info" :disabled="!this.ok" class="text-uppercase" v-on:click="sendVerificationCode()">{{ $t('buttons.BtnModifyPassword') }}</base-button>
          <base-button v-else loading simple type="info" class="text-uppercase">{{ $t('buttons.BtnModifyPassword') }}</base-button>
      </div>
    </div> -->
    <div class="row justify-content-center">
      <div class="col text-center">
        <base-button simple type="info" :disabled="!this.ok" class="text-uppercase" v-on:click="updateProfil()">{{ $t('buttons.BtnModify') }}</base-button>
      </div>
    </div>
    <!-- row 8 -->
    <!-- Edit password -->
     <modal
      :show.sync="editPasswordShow"
      body-classes="p-0"
      modalClasses="modal-dialog-centered"
      class="modal-listbase">
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">
        <h5 class="description text-center">
          Veuillez vérifier votre boite e-mail, vous allez bientôt recevoir notre message.
          Si vous n’avez pas reçu le mail d’ici quelques minutes, pensez à vérifier vos courriers indésirables,
          ou bien consultez notre support technique: webmaster@weedoit.fr
        </h5>
        <form @submit.prevent="resetPassword">
          <div class="form-row">
            <base-input
            class="col"
            v-validate="modelValidations.verificationCode"
            name="verificationCode"
            :error="getError('verificationCode')"
            v-model="model.verificationCode"
            type="text"
            :placeholder="$t('inputs.verificationCode').toUpperCase()"
            :data-vv-as="$t('inputs.verificationCode')"
            >
            </base-input>
          </div>
          <div class="form-row">
            <base-input
            class="col"
            v-validate="modelValidations.newPassword"
            name="newPassword"
            ref="newPassword"
            :error="getError('newPassword')"
            v-model="model.newPassword"
            type="password"
            :placeholder="$t('inputs.newPassword').toUpperCase()"
            addon-left-icon="tim-icons icon-lock-circle"
            :data-vv-as="$t('inputs.newPassword')"
          >
          </base-input>
        </div>
        <div class="form-row">
          <base-input
          class="col"
          v-validate="'required|confirmed:newPassword'"
          name="passwordConfirmation"
          :error="getError('passwordConfirmation')"
          v-model="model.passwordConfirmation"
          type="password"
          :placeholder="$t('inputs.passwordConfirmation').toUpperCase()"
          addon-left-icon="tim-icons icon-lock-circle"
          :data-vv-as="$t('inputs.passwordConfirmation')"
          >
          </base-input>
        </div>
        <center>
          <base-button
            v-if="!loadingReset"
            :disabled="errors.any()"
            native-type="submit"
            type="info"
            v-on:click="validateUpdatePasswordForm()"
            >{{ $t("buttons.BtnModify").toUpperCase() }}
          </base-button>
          <base-button type="info" v-else loading>{{ $t("buttons.BtnModify").toUpperCase() }}</base-button>
      </center>
        </form>
      </card>
    </modal>
    <!-- Edit password -->
  </card>
</template>
<script>
import { Select, Option } from 'element-ui';
import { BaseButton,  BaseAlert } from 'src/components';
import { BaseRadio, BaseSwitch } from 'src/components/index';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import {Modal} from 'src/components';



export default {
  created() {
    this.token = localStorage.getItem("token_jwt")
    this.utilisateur = JSON.parse(localStorage.getItem('utilisateur'))
    if(this.utilisateur.multi_droit){
      this.model.annonceur = true
      this.model.affilie = true
       this.model.agence_media = true
    }else {
      if(this.utilisateur.law == "af") {
        this.model.affilie = true
      } else if(this.utilisateur.law == "an") {
        this.model.annonceur = true
         this.model.agence_media = true
      }
    }
    this.model.id = this.utilisateur.id,
    this.model.email = this.utilisateur.email,
    this.model.lastname = this.utilisateur.last_name,
    this.model.firstname= this.utilisateur.first_name,
    this.model.mobile= this.utilisateur.phone,
    this.model.countryCode = this.utilisateur.code_pays.trim(),
    this.model.address= this.utilisateur.address,
    this.model.law= this.utilisateur.law,
    this.model.langue= this.utilisateur.language,
    this.model.orias= this.utilisateur.orias,
    this.model.zipcode= this.utilisateur.postal_code,
    this.model.city= this.utilisateur.city,
    this.model.country= this.utilisateur.country,
    this.model.society= this.utilisateur.society,
    this.model.activity= this.utilisateur.activity_area,
    this.model.function= this.utilisateur.function
    this.model.skype= (this.utilisateur.skype == 'null') ? '' : this.utilisateur.skype
    this.model.tva= (this.utilisateur.tva_intracommunautaire == 'null') ? '' : this.utilisateur.tva_intracommunautaire
    this.model.rcs= (this.utilisateur.rcs == 'null') ? '' : this.utilisateur.rcs
    this.model.type_of_broadcast= (this.utilisateur.type_of_broadcast == 'null') ? '' : this.utilisateur.type_of_broadcast
    this.model.url_site= (this.utilisateur.url_site == 'null') ? '' : this.utilisateur.url_site
    this.model.thematic= (this.utilisateur.thematic == 'null') ? '' : this.utilisateur.thematic
    this.model.volume = (this.utilisateur.Volume == 'null') ? '' : this.utilisateur.Volume
    this.model.newsletter= (this.utilisateur.Newsletter==1) ? true : false;
    this.model.emails_dedies= (this.utilisateur.dedicated_email==1) ? true : false;
    this.model.emailings_cibles= (this.utilisateur.target_email==1) ? true : false;
    this.model.emailings_geolocalises= (this.utilisateur.geolocated_email==1) ? true : false;
    this.model.incentive= (this.utilisateur.incentive==1) ? true : false;
    this.model.clic_validation= (this.utilisateur.click_validation==1) ? true : false;
    this.model.co_registration= (this.utilisateur.co_registration==1) ? true : false;
    this.model.marque_blanche= (this.utilisateur.white_mark==1) ? true : false;
    this.model.site_under= (this.utilisateur.site_under==1) ? true : false;
    this.model.autres = (this.utilisateur.autres_procedes_diffusion==1) ? true : false;
  },
  components: {
    BaseSwitch,
    VueGoogleAutocomplete,
    BaseAlert,
    BaseButton,
    BaseRadio,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
  },
  data() {
    return {
      ok:false,
      showErrorMobile:false,
      showErrorAddress:false,
      boll:false,
      notValide:false,
      from_address : {},
      utilisateur:{},
      countryPhone:[],
      token:'',
      countrysCode: ['FR', 'MA', 'IT', 'US', 'SE', 'DK', 'DE', 'NL', 'CH', 'LU', 'GB', 'BR', 'NO', 'AE', 'BE', 'PT', 'FI', 'ZA', 'ES', 'AU', 'PL', 'NZ','HK', 'MT', 'MG','MU', 'TN', 'DZ', 'IE', 'SC','AL','UA' ,'UY', 'EE', 'IL'],
      compliteAddress:{},
      model: {
        id:null,
        email:'',
        lastname: '',
        firstname: '',
        mobile: null,
        indicatif: null,
        countryCode: null,
        address: '',
        law: '',
        langue: '',
        affilie: false,
        annonceur: false,
        agence_media: false,
        orias: '',
        zipcode: '',
        city: '',
        country: '',
        society: '',
        activity: '',
        function: '',
        skype:'',
        tva:'',
        rcs:'',
        type_of_broadcast:'',
        url_site:'',
        thematic: '',
        volume: '',
        newsletter: null,
        emails_dedies: null,
        emailings_cibles: null,
        emailings_geolocalises: null,
        incentive: null,
        clic_validation: null,
        co_registration: null,
        marque_blanche: null,
        site_under: null,
        autres: null,
        verificationCode: '',
        newPassword: '',
        passwordConfirmation: ''
      },
       thematics:[
        'Annonces',
        'Actualité-Média-Information',
        'Animaux',
        'Ads',
        'Arts et Culture',
        'Bons plans',
        'Cadeaux',
        'Caritatif',
        'Charme',
        'Communautaire',
        'Compétition',
        'Concours',
        'Emploi-Formation',
        'Famille',
        'Finance',
        'Financiar',
        'Fleurs-Jardin',
        'Gastronomie-vin',
        'Health-beauty',
        'Immobilier',
        'Inamovible',
        'Informatique',
        'Internet',
        'Jeux vidéos',
        'Jeux-Loteries-Casino',
        'Jouets-Jeux de société',
        'Librairie-Presse',
        'Loisirs',
        'Lotteries Casino Games',
        'Maison',
        'Media-News-Information',
        'Mode',
        'Musique',
        'Noticias-Media-Info',
        'Portail',
        'Professionnel',
        'PTR-PTC-autosurf',
        'Rencontres',
        'Santé-Beauté',
        'Sciences',
        'Son-Photo-Vidéo',
        'Sport',
        'Télécommunication',
        'Transports',
        'Ventes privées',
        'Vie pratique',
        'Voyages',
        'Autre'
      ],
      diffusiontype: [
        "Avantages consommateurs",
        "Content & Influence marketing",
        "Display",
        "Email Marketing",
        "Emulation",
        "Génération de leads",
        "Iframe",
        "Mailing",
        "Mobile",
        "Native",
        "Push notification",
        "Remarketing",
        "Reseau sociaux",
        "Search",
        "SMS"
      ],
      languageOptions: [
        'Français',
        'Anglais'
      ],
      countryOptions: [
        'France',
        'Afrique du Sud',
        'Allemagne',
        'Belgique',
        'Brésil',
        'Danemark',
        'Espagne',
        'États-Unis',
        'Finlande',
        'Australie',
        'Italie',
        'Maroc',
        'Madagascar',
        'Malte',
        'Norvège',
        'Nouvelle-Zélande',
        'Pays-Bas',
        'Pologne',
        'Portugal',
        'Royaume-Uni',
        'Suède',
        'Suisse',
        'Canada',
        'Luxembourg',
        'Émirats Arabe unis',
        'Hong Kong',
        'Gibraltar',
        'Ile Maurice',
        'Tunisie',
        'Algérie',
        'Irlande',
        'Seychelles',
        'Albanie',
        'Ukraine',
        'Uruguay',
        'Estonie',
        'Israël'
      ],
      activity: [
        "Accessoires de mode",
        "Accessoires électroniques",
        "Achat groupé",
        "Agences de voyage",
        "Animaux",
        "Appareils électroménagers",
        "Assurances",
        "Audiovisuel",
        "Autocars",
        "Automobiles",
        "Bébé & Nouveau-né",
        "Biens de consommation courante",
        "Billetterie",
        "Bricolage",
        "Cadeaux & Fleurs",
        "Cartes de crédit",
        "Cartes prépayées",
        "Chaussures",
        "Compagnies aériennes",
        "Comptes courants",
        "Crédits immobiliers",
        "Croisières & Ferries",
        "Distribution de biens culturels et de loisirs",
        "Distribution de produits électroniques",
        "Éducation, Formation & Recrutement",
        "Énergie & Eau",
        "Épargnes & Placements",
        "Équipements sportifs",
        "Érotisme",
        "Forfaits mobile",
        "Fournisseurs d'accès Internet",
        "Fournitures de bureau",
        "Gadgets",
        "Génération de prospects",
        "Grands magasins",
        "Habillement",
        "Habillement Enfant",
        "Habillement Femme",
        "Habillement Homme",
        "Haut débit mobile",
        "Hébergement de sites Internet",
        "Hôtels & Hébergements",
        "Immobilier",
        "Jeux & Jouets",
        "Jeux en ligne",
        "Jeux vidéo & PC",
        "Jeux-concours",
        "Joaillerie",
        "Lingerie",
        "Livres & Abonnements",
        "Location de voitures",
        "Maison & Jardin",
        "Matériel photographique",
        "Mobilier & Ameublement",
        "Musique & DVD",
        "Œuvres caritatives",
        "Opérateurs Câble & Satellite",
        "Opérateurs de réseaux",
        "Ordinateurs",
        "Prêts",
        "Produits écologiques",
        "Produits pharmaceutiques",
        "Rencontres",
        "Santé & Beauté",
        "Séjours locaux",
        "Services commerciaux (B2B)",
        "Services de Télécommunication B2B",
        "Services d'impression & Photos",
        "Services Utilitaires B2B",
        "Stationnement & Transferts aéroport",
        "Téléchargements de logiciels",
        "Téléchargements de Musique & Jeux",
        "Téléchargements mobiles",
        "Tourisme & Attractions",
        "Trains",
        "TV numérique & Vidéo à la Demande",
        "Vêtements de sport",
        "Vin, Spiritueux & Tabac",
        "Voyance"
      ],
      modelValidations: {
        street: {
          required: true,
          min: 5
        },
        city: {
          required: true
        },
        country: {
          required: true
        },
        password: {
          required: true,
          password: true
        },
        newPassword: {
          required: true,
          password: true
        },
        verificationCode :{
          required: true,
          verificationCode: true
        }
      },
      editPasswordShow: null,
      loading: false,
      loadingReset: false,
      ereurs: []
    };
  },
  methods: {
    checkAddress(){
      if(document.getElementById("from_address").value == ''){
        this.ok = false
        this.showErrorAddress = true
      } else {
        this.ok = true
        this.showErrorAddress = false
      }
    },
    checkMobile(){
      if(this.model.mobile == ''){
          this.ok = false
          this.showErrorMobile = true;
      } else {
        this.ok = true
        this.showErrorMobile = false;
      }
    },
    onUpdate (payload) {
      this.model.countryCode = payload.countryCode
      this.model.indicatif = payload.countryCallingCode
    },
    updateProfil(){
      let changeLaw = false
      let formData = new FormData()
      if (!this.utilisateur.multi_droit) {
        if(this.utilisateur.law == "af") {
          if(this.model.annonceur) {
            formData.append('law', "an")
            changeLaw = true
          }
          else {
            formData.append('law', this.utilisateur.law)
          }
        }
        else if(this.utilisateur.law == "an") {
          if(this.model.affilie) {
            formData.append('law', "af")
            changeLaw = true
          }
          else {
            formData.append('law', this.utilisateur.law)
          }
        }
        else {
          formData.append('law', this.utilisateur.law)
        }
      }
      else {
        formData.append('law', this.utilisateur.law)
      }

      formData.append('id', this.model.id),
      formData.append('first_name', this.model.firstname)
      formData.append('last_name', this.model.lastname )
      formData.append('society', this.model.society)
      formData.append('activity_area', this.model.activity)
      formData.append('address', this.model.address)
      formData.append('city', this.model.city)
      formData.append('country', this.model.country)
      formData.append('function', this.model.function)
      formData.append('phone', this.model.mobile)
      formData.append('postal_code', this.model.zipcode)
      formData.append('code_pays', this.model.countryCode)
      formData.append('indicatif', this.model.indicatif)
      formData.append('language', this.model.langue)
      formData.append('orias', this.model.orias)
      formData.append('skype', this.model.skype)
      formData.append('tva_intracommunautaire', this.model.tva)
      formData.append('rcs', this.model.rcs)
      formData.append('type_of_broadcast', this.model.type_of_broadcast)
      formData.append('url_site', this.model.url_site)
      formData.append('thematic', this.model.thematic)
      formData.append('volume', this.model.volume)
      formData.append('newsletter', (this.model.newsletter==true)?1:0)
      formData.append('dedicated_email', (this.model.emails_dedies==true)?1:0)
      formData.append('target_email', (this.model.emailings_cibles==true)?1:0)
      formData.append('geolocated_email', (this.model.emailings_geolocalises==true)?1:0)
      formData.append('incentive', (this.model.incentive==true)?1:0)
      formData.append('click_validation', (this.model.clic_validation==true)?1:0)
      formData.append('co_registration', (this.model.co_registration==true)?1:0)
      formData.append('white_mark', (this.model.marque_blanche==true)?1:0)
      formData.append('site_under', (this.model.site_under==true)?1:0)
      formData.append('autres_procedes_diffusion', (this.model.autres==true)?1:0)
      this.axios
          .post("https://api-auth-plf.wee-do-it.net/api/v1/update_profil",formData,{
            headers: {
              'authorization': `Bearer ${this.token}`
            }
          })
          .then(response => {
            this.$notify({type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: this.$t('alerts.ProfileUpdate')});
            localStorage.setItem('utilisateur',JSON.stringify(response.data.response))
            if(changeLaw) {
              this.$store.commit('signOut')
              location.reload()
            }

          })
          .catch(err => {
            if (err.response.status == "401") {
              this.$store.commit('signOut')
              location.reload()
            }
          });
    },
    getError(fieldName) {
      if(this.errors.items.length > 0){
        this.ok = false
      }else{
        this.ok = true
      }
      return this.errors.first(fieldName);
    },
    getMobileError(){
      if(this.getError('mobile') == 'Le champ mobile est obligatoire'){
        this.showErrorMobile = true;
      }
    },
    validate() {
      if(this.model.mobile == '' || this.model.mobile.length != 14){
          this.showErrorMobile = true;
        } else {
          this.showErrorMobile = false;
        }

      if(this.model.address == ''){
        this.showErrorAddress = true
      } else {
        this.showErrorAddress = false
      }
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        this.ok = !res;
        return res;
      });
    },
    getFromAddress(from_address, placeResultData, id) {
        this.model.address = document.getElementById("from_address").value
        this.compliteAddress = from_address;
        this.model.zipcode = this.compliteAddress.postal_code;
        this.model.city = this.compliteAddress.locality;
        this.model.country = this.compliteAddress.country;
    },
    getToAddress(to_address, placeResultData, id){
        this.to_address = to_address;
    },
    handleError(error){
      alert(error)
    },
    sendVerificationCode(){
      this.loading = true
      this.ereurs = []
      console.log('update password ...')
      let formData = new FormData()
      formData.append('email', this.model.email)
      this.axios
           .post("https://api-auth-plf.wee-do-it.net/api/v1/initiate-password-reset/send-verification-code",formData,{
            headers: {
              'authorization': `Bearer ${this.token}`
            }
          })
          .then(response => {
            console.log(response.data)
            this.loading = false
            this.editPasswordShow = true;
          })
          .catch(error => {
            this.loading = false
            console.log(error.response.data)
            this.ereurs.push(this.translatemsg(error.response.data.message))
          });
    },
    validateUpdatePasswordForm(){
      this.$validator.validateAll().then(isValid => {
        if(isValid){
          this.resetPassword
        }
      });
    },
    resetPassword(){
      console.log('reset password ...')
      this.loadingReset = true
      this.ereurs = []
      let formData = new FormData()
      formData.append('email', this.model.email)
      formData.append('password', this.model.newPassword)
      formData.append('code', this.model.verificationCode)
      this.axios
           .post("https://api-auth-plf.wee-do-it.net/api/v1/confirm-password-reset",formData,{
            headers: {
              'authorization': `Bearer ${this.token}`
            }
          })
          .then(response => {
            this.loadingReset = false
            console.log(response.data)
            this.$store.commit('signOut')
            location.reload()
          })
          .catch(error => {
            this.loadingReset = false
            this.editPasswordShow = false;
             console.log(error.response.data)
            this.ereurs.push(this.translatemsg(error.response.data.message))
          });
    },
    translatemsg(msg) {
      switch (msg) {
        //test
        case "UserNotFoundException":
          return this.$t('errors.UserNotFoundException');
        //test
        case "LimitExceededException":
          return this.$t('errors.LimitExceededException');
        //test
        case "CodeMismatchException":
          return this.$t('errors.CodeMismatchException')
        case("InvalidPasswordException"):
          return this.$t('errors.InvalidPasswordException');
        case("ExpiredCodeException"):
          return this.$t('errors.ExpiredCodeException');
      }
      return this.$t('errors.GeneralAWSCognitoError');
    }
  }
};
</script>
