<template>
  <div>
    <div class="row justify-content-center mt-5">
      <div class="col-sm-10">
        <base-input
          name="email"
          required
          :placeholder="$t('inputs.Email').toUpperCase()"
          v-model="model.email"
          v-validate="modelValidations.email"
          type="email"
          :error="getError('email')"
          addon-left-icon="tim-icons icon-email-85"
          :disabled="emaildisable"
        >
        </base-input>
      </div>
      <div class="col-sm-10">
        <base-input
          v-validate="modelValidations.password"
          name="password"
          :error="getError('password')"
          v-model="model.password"
          type="password"
          :placeholder="$t('inputs.Password').toUpperCase()"
          addon-left-icon="tim-icons icon-lock-circle"
          data-vv-as="mot de passe"
        >
        </base-input>
      </div>
      <el-popover trigger="hover"
        placement="top">
        <div>
          <h3 class="popover-header">Popover on Top</h3>
          <div class="popover-body">Here will be some very useful information about his popover.</div>
        </div>
        <div class="col-sm-10">
          <base-input
            v-validate="modelValidations.password"
            name="password"
            :error="getError('password')"
            v-model="model.password"
            type="password"
            :placeholder="$t('inputs.Password')"
            addon-left-icon="tim-icons icon-lock-circle"
            data-vv-as="mot de passe"
            slot="reference"
          >
          </base-input>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { config } from "src/cognito_conf.js";
var AMAZON_IDENTITY = require("amazon-cognito-identity-js");

export default {
  created () {
    if (this.getemail != '') {
      this.model.email = this.getemail
      this.emaildisable = true
    }
  },
  data() {
    return {
      emaildisable: false,
      model: {
        email: '',
        password: '',
      },
      modelValidations: {
        email: {
          required: true,
          email: true,
          max: 60,
          min:5
        },
        password: {
          required: true,
          password: true
        }
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async validate() {
      this.$store.commit('setcheckuserExist', true)
      let emailexist = await this.EmailExist()
      this.$store.commit('setcheckuserExist', false)
      if(emailexist) {
        this.$emit('on-UserExist', this.$t('errors.UserExistsException'))
        return false
      }
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    async EmailExist() {
      let emailexist
      var authenticationData = {
        Username: this.model.email,
        Password: "this.formData.password"
      };
      var authenticationDetails = new AMAZON_IDENTITY.AuthenticationDetails(
        authenticationData
      );
      var poolData = {
        UserPoolId: config.cognito.userPoolId,
        ClientId: config.cognito.clientId
      };
      var userPool = new AMAZON_IDENTITY.CognitoUserPool(poolData);
      var userData = {
        Username: this.model.email,
        Pool: userPool
      };
      var cognitoUser = new AMAZON_IDENTITY.CognitoUser(userData);
      await cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(result) {
        },
        onFailure(err) {
          if(err.code == "UserNotFoundException")
            emailexist = false
          else
            emailexist = true
        }
      })
      await this.sleep(3000)
      return emailexist
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  computed: {
    ...mapGetters([
      'getemail'
    ])
  }
};
</script>
<style></style>
