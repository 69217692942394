<template>
  <div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Checktocken',
  created() {
    let token = this.$route.params.token;
    this.axios({
      method: 'post',
      url: "https://api-auth-plf.wee-do-it.net/api/v1/is_user_valide",
      headers: { 'authorization': `Bearer ${token}` }
    })
    .then(response => {
      if(response.data.code == "U200_03"){
        // l'utilisateur en attente de validation
        this.setapierreur(response.data.message)
        this.$router.push({ name: 'Login'})
      } else if(response.data.code == "U200_01"){
        // l'utilisateur n'est pas enregistré
        this.setemail(response.data.response.email)
        this.$router.push({ name: 'Register'})
      }
      else if(response.data.code == "U200_02"){
        // l'utilisateur est valide
        localStorage.setItem('utilisateur',JSON.stringify(response.data.response.utilisateur))
        localStorage.setItem("token_jwt", response.data.response.utilisateur.api_token)
        this.router.push("Home")
      } else {
        console.log("third")
      }
    })
    .catch(err => {
      this.setapierreur(this.$t('errors.ServerError'))
      this.$router.push({ name: 'Login'})
    });
  },
  methods: {
    ...mapMutations([
      'setemail',
      'setapierreur'
    ])
  }
};
</script>
