<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <h5 class="info-text">{{ $t("labels.Profile").toUpperCase() }}</h5>
      </div>
    </div>
    &nbsp;
    <!-- row 2 -->
    <!-- <div class="row justify-content-center">
      <div class="col-sm-5">
        <center>
          <base-checkbox inline class="mb-3" v-model="model.affilie">
            {{ $t('inputs.Law.Affiliates').toUpperCase() }}
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="model.annonceur">
            {{ $t('inputs.Law.Advertiser').toUpperCase() }}
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="model.annonceur">
            Agence média
          </base-checkbox>
        </center>
      </div>
      <div class="col-sm-5">
        <center>
          <label class="error" style="color:#ec250d" v-show="showErrorLaw"> Le champ droit est obligatoire </label><br />
        </center>
      </div>
    </div>
    &nbsp; -->
    <!-- row 3 -->
    <div class="row justify-content-center">

      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Society') + ' *'"
          name="society"
          v-model="model.society"
          :error="getError('society')"
          v-validate="'required|max:60'"
          :data-vv-as="$t('inputs.Society')"
        >
        </base-input>
      </div>

      <div class="col-sm-5">
        <base-input  :label="$t('inputs.Activity')  + ' *'" :error="getError('activity')">
          <el-select
            filterable
            v-model="model.activity"
            placeholder="CHOISIR"
            class="select-primary"
            name="activity"
            v-validate="'required|max:60|min:3'"
            :data-vv-as="$t('inputs.Activity')"
            >
            <el-option
              v-for="a in activity"
              class="select-primary"
              :label="a.toUpperCase()"
              :value="a"
              :key="a"
            ></el-option>
          </el-select>
        </base-input>
      </div>
    </div>
<!--
      <div class="col-sm-10">
          <base-checkbox inline v-model="model.affilie">
            {{ $t('inputs.Law.Affiliates').toUpperCase() }}
          </base-checkbox>
          <base-checkbox inline  v-model="model.annonceur">
            {{ $t('inputs.Law.Advertiser').toUpperCase() }}
          </base-checkbox>
          <base-checkbox inline v-model="model.annonceur">
            Agence média
          </base-checkbox>
          <label class="error" style="color:#ec250d" v-show="showErrorLaw"> Le champ droit est obligatoire </label>
      </div> -->

      <div class="row justify-content-center">

        <div class="col-sm-10 mt-3">
          <center>
            <base-checkbox inline class="mb-2" v-model="model.affilie">
              {{ $t('inputs.Law.Affiliates').toUpperCase() }}
            </base-checkbox>
            <base-checkbox inline class="mb-2" v-model="model.annonceur">
              {{ $t('inputs.Law.Advertiser').toUpperCase() }}
            </base-checkbox>
            <base-checkbox inline class="mb-2" v-model="model.agence_media">
              AGENCE MÉDIA
            </base-checkbox>
          </center>
        </div>
        <div class="col-sm-5">
          <center>
            <label class="error" style="color:#ec250d" v-show="showErrorLaw"> Le champ droit est obligatoire </label><br />
          </center>
        </div>
      </div>
    &nbsp;
    <div class="row justify-content-center">
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Lastname') + ' *'"
          name="lastname"
          :placeholder="$t('inputs.Lastname').toUpperCase()"
          v-model="model.lastname"
          :error="getError('lastname')"
          v-validate="'required|max:60|min:3'"
          addon-left-icon="tim-icons icon-single-02"
          :data-vv-as="$t('inputs.Lastname')"
        >
        </base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Firstname') + ' *'"
          name="firstname"
          :placeholder="$t('inputs.Firstname').toUpperCase()"
          v-model="model.firstname"
          :error="getError('firstname')"
          v-validate="'required|max:60|min:3'"
          addon-left-icon="tim-icons icon-caps-small"
          :data-vv-as="$t('inputs.Firstname')"
        >
        </base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Function') + ' *'"
          name="function"
          v-model="model.function"
          :error="getError('function')"
          v-validate="'required|max:60|min:3'"
          :data-vv-as="$t('inputs.Function')"
        >
        </base-input>
      </div>

      <div class="col-sm-5">
        <label>{{ $t('inputs.Address').toUpperCase() + " *" }}</label>
        <vue-google-autocomplete
            name="address"
            id="from_address"
            classname="form-control"
            :placeholder="$t('inputs.Address').toUpperCase()"
            v-model="model.address"
            v-on:placechanged="getFromAddress"
            :error="showErrorAddress"
            v-bind:style="showErrorAddress ? 'border-color:#ec250d' : ''"
            @change="checkAddress()"
        >
        </vue-google-autocomplete>
        <label class="error" style="color:#ec250d" v-show="showErrorAddress"> Le champ adresse est obligatoire </label>
      </div>

      <div class="col-sm-5">
        <base-input
          name="zipcode"
          :label="$t('inputs.zipcode')"
          required
          v-model="model.zipcode"
          :error="getError('zipcode')"
          v-validate="'required|max:60|min:4'"
          :data-vv-as="$t('inputs.zipcode')"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input
          name="city"
          :label="$t('inputs.City')"
          required
          v-model="model.city"
          :error="getError('city')"
          v-validate="'required|min:3|max:60'"
          :data-vv-as="$t('inputs.City')"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <base-input :label="$t('inputs.Country') + ' *'" :error="getError('country')">
          <el-select
            filterable
            v-model="model.country"
            class="select-primary"
            name="country"
            v-validate="'required|max:60|min:3'"
            :placeholder="$t('inputs.SelectCountry').toUpperCase()"
            :data-vv-as="$t('inputs.Country')"
          >
            <el-option
              v-for="country in countryOptions"
              class="select-primary"
              :label="country.toUpperCase()"
              :value="country"
              :key="country"
            ></el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.TvaIntra')"
          name="tva"
          v-model="model.tva"
          :data-vv-as="$t('inputs.TvaIntra')"
        >
        </base-input>
      </div>

      <div class="col-sm-5">
        <label>{{ $t('inputs.PhoneNumber').toUpperCase() + " *"}}</label>
        <vue-phone-number-input
          name="mobile"
          valid-color="#54347C"
          :no-example="true"
          :dark="true"
          :border-radius="8"
          dark-color="#000"
          error-color="#ec250d"
          :only-countries="this.countrysCode"
          :default-country-code="'FR'"
          :translations="{
            countrySelectorLabel: $t('inputs.CountryCode'),
            phoneNumberLabel: $t('inputs.PhoneNumber').toUpperCase(),
          }"
          color="#988fc3"
          :error="showErrorMobile"
          v-validate="'required'"
          v-model="model.mobile"
          @update="onUpdate"
        >
        </vue-phone-number-input>
        <label class="error" style="color:#ec250d" v-show="showErrorMobile"> Le champ numéro de téléphone est obligatoire </label>
        <div class="input-group">
        </div>
      </div>

      <div class="col-sm-5">
        <base-input
          label="Skype"
          name="skype"
          :error="getError('skype')"
          v-validate="'min:3'"
          v-model="model.skype"
        >
        </base-input>
      </div>

      <div class="col-sm-5">
        <base-input :label="$t('inputs.Language') + ' *'" :error="getError('langue')">
          <el-select
            v-model="model.langue"
            class="select-primary"
            name="langue"
            v-validate="'required|min:2'"
            :placeholder="$t('inputs.SelectLang').toUpperCase()"
            :data-vv-as="$t('inputs.Language')"
          >
            <el-option
              v-for="l in languageOptions"
              class="select-primary"
              :label="l.toUpperCase()"
              :value="l"
              :key="l"
            ></el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-sm-5" v-if="model.affilie">
        <base-input :label="$t('inputs.Themes') + ' *'" :error="getError('thématiques')">
          <el-select
            filterable
            v-model="model.thematic"
            name="thématiques"
            v-validate="'required|min:2'"
            class="select-primary"
            :placeholder="$t('inputs.Themes').toUpperCase()"
            :data-vv-as="$t('inputs.Themes')"
          >
            <el-option
              v-for="t in thematics"
              class="select-primary"
              :label="t.toUpperCase()"
              :value="t"
              :key="t"
            ></el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-sm-5" v-if="model.affilie">
        <base-input
          :label="$t('inputs.UrlSite') + ' *'"
          name="URL"
          v-model="model.url_site"
          :error="getError('URL')"
          v-validate="'required|url|min:5'"
        >
        </base-input>
      </div>

      <div class="col-sm-5" v-if="model.affilie">
        <base-input :label="$t('inputs.DiffusionType') + ' *'" :error="getError('Type de diffusion')">
          <el-select
            filterable
            v-model="model.type_of_broadcast"
            class="select-primary"
            v-validate="'required'"
            name="Type de diffusion"
            :placeholder="$t('inputs.DiffusionType').toUpperCase()"
            :data-vv-as="$t('inputs.DiffusionType')"
          >
            <el-option
              v-for="(value, key) in DiffusionTypeList"
              class="select-primary"
              :label="value.toUpperCase()"
              :value="value"
              :key="key"
            ></el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          label="RCS *"
          name="rcs"
          v-validate="'required|min:2'"
          v-model="model.rcs"
          :error="getError('rcs')"
        >
        </base-input>
      </div>

      <div class="col-sm-5" >
        <base-input
          label="Volume"
          name="volume"
          v-validate="'min:2'"
          v-model="model.volume"
          :error="getError('volume')"
        >
        </base-input>
      </div>
      <div class="col-sm-5">
        <transition name="fade">
          <base-input
            label="Orias"
            name="orias"
            required
            v-model="model.orias"
            :error="getError('orias')"
            v-validate="'required|min:2'"
            v-if="(model.annonceur || model.agence_media) && model.activity == 'Assurances'"
          >
          </base-input>
        </transition>
      </div>
      <div class="col-sm-5"></div>
      <div class="col-sm-10" v-if="model.affilie">
        <base-input label="commentaire">
          <textarea v-model="model.comment" class="form-control" rows="3"
          ></textarea>
        </base-input>
      </div>

      <div class="col-sm-10" v-if="model.affilie">
        <base-checkbox inline class="mb-2" v-model="model.CGV_affilie" name="CGV" :error="getError('CGV')" v-validate="'required'">
          Cochez cette case pour confirmer que vous avez lu et accepté <label style="cursor: pointer; text-decoration: underline;" @click="goConditions()">les conditions générales de vente</label>
          <label class="error" style="color:#ec250d" v-show="showErrorCGV"> Le champ CGV est obligatoire </label>
        </base-checkbox>
      </div>
    </div>



    <!-- row 4 -->

    <!-- row 5 -->
    <!-- <div class="row justify-content-center">
      <div class="col-sm-5">
        <transition name="fade">
          <base-input
            label="Orias"
            name="orias"
            required
            v-model="model.orias"
            :error="getError('orias')"
            v-validate="'required'"
            v-if="model.annonceur && model.activity == 'Assurances'"
          >
          </base-input>
        </transition>
      </div>
    </div> -->
    &nbsp;
    <div class="row justify-content-center">
      <div class="col-lg-10 text-center">
        <image-upload type="avatar" @change="onImageChange"></image-upload>
      </div>
      <label class="error" style="color:#ec250d">{{imgerror}}</label>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-10">
        * {{ $t('labels.Requiredfields') }}
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui';
import { ImageUpload} from 'src/components';
import { BaseRadio } from 'src/components/index';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import {Popover} from 'element-ui'
import { Thematiques } from "src/pages/Filters/Thematiques";

export default {
  components: {
    VueGoogleAutocomplete,
    ImageUpload,
    BaseRadio,
    [Select.name]: Select,
    [Option.name]: Option,
    [Popover.name]: Popover
  },
  data() {
    return {
      showErrorLaw: false,
      showErrorCGV: false,
      showErrorMobile:false,
      showErrorAddress:false,
      imgerror: '',
      countrysCode: ['FR', 'MA', 'IT', 'US', 'SE', 'DK', 'DE', 'NL', 'CH', 'LU', 'GB', 'BR', 'NO', 'AE', 'BE', 'PT', 'FI', 'ZA', 'ES', 'AU', 'PL', 'NZ','HK', 'MT', 'MG','MU', 'TN', 'DZ', 'IE', 'SC' ,'AL','UA', 'UY', 'EE', 'IL'],
      model: {
        lastname: '',
        firstname: '',
        mobile: '',
        cMobile: null,
        indicatif: null,
        address: '',
        affilie: true,
        annonceur: false,
        agence_media: false,
        langue: '',
        orias: '',
        zipcode: '',
        city: '',
        country: '',
        society: '',
        activity: '',
        function: '',
        skype:'',
        tva:'',
        rcs:'',
        type_of_broadcast:'',
        url_site:'',
        thematic: '',
        volume: '',
        images: null,
        comment: '',
        CGV_affilie: false
      },
      DiffusionTypeList: [
        "Avantages consommateurs",
        "Content & Influence marketing",
        "Display",
        "Email Marketing",
        "Emulation",
        "Génération de leads",
        "Iframe",
        "Mailing",
        "Mobile",
        "Native",
        "Push notification",
        "Remarketing",
        "Reseau sociaux",
        "Search",
        "SMS"
      ],
      thematics: Thematiques.map(function (th) {
        return th.categorie;
      }),
      languageOptions: [
        'Français',
        'Anglais',
        'Espagnol'
      ],
      countryOptions: [
        'France',
        'Afrique du Sud',
        'Allemagne',
        'Belgique',
        'Brésil',
        'Danemark',
        'Espagne',
        'États-Unis',
        'Finlande',
        'Australie',
        'Italie',
        'Maroc',
        'Madagascar',
        'Malte',
        'Norvège',
        'Nouvelle-Zélande',
        'Pays-Bas',
        'Pologne',
        'Portugal',
        'Royaume-Uni',
        'Suède',
        'Suisse',
        'Canada',
        'Luxembourg',
        'Émirats Arabe unis',
        'Hong Kong',
        'Gibraltar',
        'Ile Maurice',
        'Tunisie',
        'Algérie',
        'Irlande',
        'Seychelles',
        'Albanie',
        'Ukraine',
        'Uruguay',
        'Estonie',
        'Israël'
      ],
      activity: [
        "Accessoires de mode",
        "Accessoires électroniques",
        "Achat groupé",
        "Agences de voyage",
        "Animaux",
        "Appareils électroménagers",
        "Assurances",
        "Audiovisuel",
        "Autocars",
        "Automobiles",
        "Bébé & Nouveau-né",
        "Biens de consommation courante",
        "Billetterie",
        "Bricolage",
        "Cadeaux & Fleurs",
        "Cartes de crédit",
        "Cartes prépayées",
        "Chaussures",
        "Compagnies aériennes",
        "Comptes courants",
        "Crédits immobiliers",
        "Croisières & Ferries",
        "Distribution de biens culturels et de loisirs",
        "Distribution de produits électroniques",
        "Éducation, Formation & Recrutement",
        "Énergie & Eau",
        "Épargnes & Placements",
        "Équipements sportifs",
        "Érotisme",
        "Forfaits mobile",
        "Fournisseurs d'accès Internet",
        "Fournitures de bureau",
        "Gadgets",
        "Génération de prospects",
        "Grands magasins",
        "Habillement",
        "Habillement Enfant",
        "Habillement Femme",
        "Habillement Homme",
        "Haut débit mobile",
        "Hébergement de sites Internet",
        "Hôtels & Hébergements",
        "Immobilier",
        "Jeux & Jouets",
        "Jeux en ligne",
        "Jeux vidéo & PC",
        "Jeux-concours",
        "Joaillerie",
        "Lingerie",
        "Livres & Abonnements",
        "Location de voitures",
        "Maison & Jardin",
        "Matériel photographique",
        "Mobilier & Ameublement",
        "Musique & DVD",
        "Œuvres caritatives",
        "Opérateurs Câble & Satellite",
        "Opérateurs de réseaux",
        "Ordinateurs",
        "Prêts",
        "Produits écologiques",
        "Produits pharmaceutiques",
        "Rencontres",
        "Santé & Beauté",
        "Séjours locaux",
        "Services commerciaux (B2B)",
        "Services de Télécommunication B2B",
        "Services d'impression & Photos",
        "Services Utilitaires B2B",
        "Stationnement & Transferts aéroport",
        "Téléchargements de logiciels",
        "Téléchargements de Musique & Jeux",
        "Téléchargements mobiles",
        "Tourisme & Attractions",
        "Trains",
        "TV numérique & Vidéo à la Demande",
        "Vêtements de sport",
        "Vin, Spiritueux & Tabac",
        "Voyance"
      ]
    };
  },
  methods: {
    goConditions() {
      window.open("https://weedoit-media.s3.eu-central-1.amazonaws.com/CGV/CGV-weedoperf+%26+weedocoreg+-+2021+v+final.pdf", '_blank');
    },
    checkAddress(){
      if(document.getElementById("from_address").value == ''){
        this.showErrorAddress = true
      } else {
        this.model.address = document.getElementById("from_address").value
        this.showErrorAddress = false
      }
    },
    onImageChange(file) {
      this.imgerror = ''
      if(file==null)
        return
      if(file.type=="image/png" || file.type=="image/jpeg") {
        this.model.images = file;
        return
      }
      this.model.images = null
      this.imgerror = "le type d'image doit être : png | jpeg"
    },
    onUpdate (payload) {
        this.model.cMobile = payload.countryCode
        this.model.indicatif = payload.countryCallingCode
      },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      if(this.model.mobile == ''){
        this.showErrorMobile = true;
      } else {
        this.showErrorMobile = false;
      }

      if(this.model.affilie == false && this.model.annonceur == false && this.model.agence_media == false){
        this.showErrorLaw = true
        return false
      } else {
        this.showErrorLaw = false
      }
      if(this.model.address == ''){
        this.showErrorAddress = true
      } else {
        this.showErrorAddress = false
      }

      if (this.model.CGV_affilie == false && this.model.affilie == true) {
        this.showErrorCGV = true
        return false
      } else {
        this.showErrorCGV = false
      }

      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    getFromAddress(from_address, placeResultData, id) {
      this.model.address = document.getElementById("from_address").value
      this.compliteAddress = from_address;
      this.model.zipcode = this.compliteAddress.postal_code;
      this.model.city = this.compliteAddress.locality;
      this.model.country = this.compliteAddress.country;
    },
    getToAddress(to_address, placeResultData, id){
      this.to_address = to_address;
    }
  }
};
</script>
<style></style>
