<template>
  <div>
    <h2 class="text-center mb-5">Réinitialiser votre mot de passe</h2>
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 mr-auto">
          <div class="card card-custom-white">
            <div class="card-body">
              <base-alert type="default" dismissible v-if="ereurs.length">
                <span> 
                  <template v-if="ereurs[0].includes('Requête erronée') || ereurs[0].includes('Wrong request')">
                    {{ ereurs[0] }}
                    <u><a href="mailto:webmaster@weedoit.fr">Support</a></u>
                  </template>
                  <template v-else> {{ ereurs[0] }} </template>
                </span>
              </base-alert>
              <div class="row">
                <div class="col-md-12">
                  <p style="color:#303030">
                    Veuillez vérifier votre boite e-mail, vous allez bientôt recevoir notre message.
                    Si vous n’avez pas reçu le mail d’ici quelques minutes, pensez à vérifier vos courriers indésirables, 
                    ou bien consultez notre support technique: webmaster@weedoit.fr
                  </p>
								</div>
              </div>
              <hr style="color:#51347c; background: #51347c ">
              <form @submit.prevent="Resetpassword">
                <div class="row my-4">
                  <div class="col-md-12 my-2">
                    <input type="number" name="coderéinitialisation" class="form-control-custom" placeholder="code réinitialisation" required v-model="formData.validationcode">
                  </div>
                  <div class="col-md-12 my-2">
                    <input id="password" name="newpassword" type="password" class="form-control-custom" placeholder="Nouveau mot de passe" required v-model="formData.password">
                  </div>
                </div>
                <base-button native-type="submit" class="animation-on-hover my-4 pull-right" type="default">
                  Réinitialiser le mot de passe
                </base-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from 'src/cognito_conf.js'
var AMAZON_IDENTITY = require('amazon-cognito-identity-js')
import { BaseAlert } from 'src/components'

export default {
  components: {
    BaseAlert
  },
  data () {
    return {
      formData: {
        validationcode: '',
        password: ''
      },
      ereurs: []
    }
  },
  created () {
    document.title = 'Réinitialiser - Weedo IT'
    if (sessionStorage.getItem('UserName') == null) {
      this.$router.push({ name: 'Forgotpassword' })
    }
  },
  beforeCreate() {
    document.body.className = 'login';
  },
  methods: {
    Resetpassword () {
      this.ereurs = []
      var poolData = {
        UserPoolId: config.cognito.userPoolId,
        ClientId: config.cognito.clientId
      }
      var userPool = new AMAZON_IDENTITY.CognitoUserPool(poolData)
      var userName = sessionStorage.getItem('UserName')

      var userData = {
        Username: userName,
        Pool: userPool
      }

      var confirmationCode = this.formData.validationcode
      var password = this.formData.password
      var cognitoUser = new AMAZON_IDENTITY.CognitoUser(userData)
      cognitoUser.confirmPassword(confirmationCode, password, {
        onFailure: function (err) {
          console.log(err.code)
          this.ereurs.push(this.translatemsg(err.code))
        }.bind(this),
        onSuccess: function () {
          sessionStorage.getItem('UserName')
          this.$router.push({ name: 'Login'})
        }.bind(this)
      })
    },
    translatemsg(msg) {
      switch (msg) {
        case "CodeMismatchException":
          return this.$t('errors.CodeMismatchException')
        case("InvalidPasswordException"):
          return this.$t('errors.InvalidPasswordException');
        case("InvalidParameterException"):
          return this.$t('errors.InvalidPasswordException');
      }
      return this.$t('errors.ServerError');
    }
  }
}
</script>
