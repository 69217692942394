<template>
  <div>
    <h2 class="text-center mb-5">{{ $t('labels.StartNow') }}</h2>
    <div class="container">
      <div class="row">
        <div class="col-md-5 ml-auto">
          <div class="info-area info-horizontal mb-3 p-2 perf">
            <div class="description">
              <h3 class="info-title">Weedo Perf</h3>
              <p class="description" style="text-align: center">
                Performance marketing
              </p>
            </div>
          </div>
          <div class="info-area info-horizontal mb-3 p-2 coreg">
            <div class="description">
              <h3 class="info-title">Weedo Coreg</h3>
              <p class="description" style="text-align: center">
                Coregistration & sponsoring
              </p>
            </div>
          </div>
          <div class="info-area info-horizontal mb-3 p-2 data">
            <div class="description">
              <h3 class="info-title">Weedo Data</h3>
              <p class="description" style="text-align: center">
                Gestion & monétisation des bases de données
              </p>
            </div>
          </div>
				  <div class="info-area info-horizontal mb-3 p-2 market">
            <div class="description">
              <h3 class="info-title">Weedo Market</h3>
              <p class="description" style="text-align: center">
                Collecte & partage de leads
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-7 mr-auto">
          <div class="card card-custom-white">
            <div class="card-body">
              <base-alert type="default" dismissible v-if="ereurs.length">
                <span>  
                  <template v-if="ereurs[0].includes('Requête erronée') || ereurs[0].includes('Wrong request')">
                    {{ ereurs[0] }}
                    <u><a href="mailto:webmaster@weedoit.fr">webmaster@weedoit.fr</a></u>
                  </template>
                  <template v-else> {{ ereurs[0] }} </template>
                </span>
              </base-alert>
              <a 
                href="https://weedoitpool.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=linkedInProvider&redirect_uri=https://login.weedoit.digital/checktocken/&response_type=TOKEN&client_id=2lc6tns5ehl25dqu4dp4gmb451&scope=email openid phone"
                class="btn btn-linkedin col-md-12 my-2"
              >
                <i class="fab fa-linkedin"></i> {{ $t("buttons.BtnLogInLinkedin") }}
              </a>
              <a 
                href="https://weedoitpool.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=WeedoitOffice365Provider&redirect_uri=https://login.weedoit.digital/checktocken/&response_type=TOKEN&client_id=2lc6tns5ehl25dqu4dp4gmb451&scope=email openid phone"
                class="btn btn-google col-md-12 my-2"
              >
                <i class="fab fa-windows"></i> {{ $t("buttons.BtnLogInMicrosoft") }}
              </a>
              <hr style="color:#51347c; background: #51347c ">
              <form @submit.prevent="checkForm">
                <div class="row my-4">
                  <div class="col-md-12 my-2">
                    <input
                      id="loginEmail"
                      type="text"
                      class="form-control-custom"
                      :placeholder="$t('inputs.Email')"
                      v-model="formData.email"
                      required
                      data-hj-allow
                    />
                  </div>
                  <div class="col-md-12 my-2">
                    <input
                      id="loginpassword"
                      type="password"
                      class="form-control-custom"
                      :placeholder="$t('inputs.Password')"
                      v-model="formData.password"
                      required
                    />
                  </div>
                </div>
                <div class="text-right my-2">
                  <div class="btn-group">
                    <button type="submit" v-if="!disabled" class="btn btn-fill btn-default my-4" style="min-width: 160px;">{{ $t('buttons.BtnLogIn') }}</button>
                    <base-button v-if="disabled" class="btn btn-fill btn-default my-4" loading type="default">Chargement</base-button>
                    <button @click="$router.push({name: 'resetPasswordOldUser'})" class="btn btn-fill btn-primary btn-simple my-4">{{ $t('buttons.BtnFirstConnetion') }}</button>
                  </div>
                </div>
                <div class="text-right my-2">
                  <router-link :to="{ name: 'Forgotpassword'}">{{ $t('labels.ForgetPassword') }}</router-link>
                </div>
                <div class="text-right my-2">
                  <router-link :to="{ name: 'Register'}">{{ $t('labels.Registration') }} <button type="submit" class="btn btn-fill btn-info">{{ $t('labels.Register') }}</button>
                  </router-link>
                  
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from "src/cognito_conf.js";
var AMAZON_IDENTITY = require("amazon-cognito-identity-js");
import { mapGetters } from 'vuex'
import { BaseAlert } from 'src/components'

export default {
  components: {
    BaseAlert
  },
  name: 'Login',
  data() {
    return {
      ereurs: [],
      formData: {
        email: "",
        password: ""
      },
      disabled: false
    };
  },
  created() {
    document.title = "Login - Weedo IT";
    this.ereurs = [];
    if(this.getapierreur != '')
      this.ereurs.push(this.getapierreur);
  },
  beforeCreate() {
    document.body.className = 'login';
  },
  methods: {
    checkForm(e) {
      this.disabled = true
      this.ereurs = []
      var authenticationData = {
        Username: this.formData.email.toLowerCase().replace(/ /g, ''),
        Password: this.formData.password
      };
      var authenticationDetails = new AMAZON_IDENTITY.AuthenticationDetails(
        authenticationData
      );
      var poolData = {
        UserPoolId: config.cognito.userPoolId,
        ClientId: config.cognito.clientId
      };
      var userPool = new AMAZON_IDENTITY.CognitoUserPool(poolData);
      var userData = {
        Username: this.formData.email.toLowerCase().replace(/ /g, ''),
        Pool: userPool
      };
      var cognitoUser = new AMAZON_IDENTITY.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async function(result) {
          var accessToken = result.getIdToken().getJwtToken()
          this.axios({
            method: 'post',
            url: "https://api-auth-plf.wee-do-it.net/api/v1/is_user_valide",
            headers: { 'authorization': `Bearer ${accessToken}` }
          })
          .then(response => {
            if (response.data.code == "U200_03") {
              // l'utilisateur en attente de validation
              this.ereurs.push(this.$t("errors.UserDisabled"))
              this.$store.commit('signOut')
              this.disabled = false
            } else if (response.data.code == "U200_01"){
              this.ereurs.push(this.$t("errors.UserNotFoundException"))
              this.$store.commit('signOut')
              this.disabled = false
            } else if (response.data.code == "U200_02"){
              localStorage.setItem('utilisateur',JSON.stringify(response.data.response.utilisateur))
              localStorage.setItem('showNotif', true)
              localStorage.setItem("token_jwt", response.data.response.utilisateur.api_token)
              location.reload()
            }
          })
          .catch(err =>{
            console.error(err)
            this.ereurs.push(this.$t("errors.ServerError"))
            this.$store.commit('signOut')
            this.disabled = false
          });
        }.bind(this),
        onFailure: function(err) {
          try {
            this.ereurs.push(this.$t('errors.' + err.code));
          } catch (error) {
            this.ereurs.push(this.$t("errors.ServerError"))
          }
          localStorage.removeItem("token_jwt");
          this.disabled = false
          if(err.code == "UserNotConfirmedException") {
            cognitoUser.resendConfirmationCode(function(err, result) {
              if (err) {
              }
            });
            sessionStorage.setItem('userregister',this.formData.email)
            this.$router.push({ name: 'ValidateEmail'})
          }

          // old memebrs reset password
          // if (err.code == "PasswordResetRequiredException") {
          //   cognitoUser.forgotPassword({
          //     onSuccess: function (result) {
          //       sessionStorage.setItem('UserName', this.formData.email)
          //       this.$router.push({ name: 'resetPasswordOldUser' })
          //     }.bind(this),
          //     onFailure: function (err) {
          //     }
          //   })
          // }
        }.bind(this)
      });
    }
  },
  computed: {
    ...mapGetters([
      'getapierreur'
    ])
  },
};
</script>

<style scoped>
  .perf {
    background: url("/img/fond-perf.jpg") no-repeat #1d1e30; 
    background-size: contain;
  }
  .coreg {
    background: url("/img/fond-coreg.jpg") no-repeat #1d1e30; 
    background-size: contain;
  }
  .data {
    background: url("/img/fond-data.jpg") no-repeat #1d1e30; 
    background-size: contain;
  }
  .market {
    background: url("/img/fond-market.jpg") no-repeat #1d1e30; 
    background-size: contain;
  }

  .info-horizontal {
    text-align: left !important;
    border-radius:10px; box-shadow: 0px 0px 2px #ffffff
  }

  .description {
    color: #fff;
    font-weight: 300;
  }

  a {
    color: #000000;
    font-weight: 300;
  }

  a:hover, a:focus {
    color: #303030; 
  }
  
  strong {
    font-weight: 700;
  }
</style>